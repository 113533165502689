import { getDefaultVehicleId } from '~/account/utilities/helpers';
const UserReducer = (state = {}, action) => {
    switch (action.type) {
        case 'LOADING':
            return {
                ...state,
                loading: true
            };
        case 'LOADED':
            return {
                ...state,
                loading: false
            };
        case 'LOGIN':
            return {
                ...state,
                loading: false,
                loggedIn: true,
                user: action.user,
                token: action.token,
                defaultVehicleId: getDefaultVehicleId()
            };
        case 'UPDATE_USER':
            return {
                ...state,
                user: action.user
            };
        case 'LOGOUT':
            return {
                ...state,
                loggedIn: false,
                loading: false,
                user: {},
                defaultVehicleId: null
            };
        case 'UPDATE_DEFAULT_VEHICLE':
            return {
                ...state,
                defaultVehicleId: action.vehicleId
            };
        case 'ERROR':
            return {
                ...state,
                loading: false,
                error: action.error
            };
        default:
            return state;
    }
};

export default UserReducer;
