import {
    filter,
    find,
    findIndex,
    includes,
    isArray,
    isEmpty,
    map,
    remove,
    startsWith,
    differenceBy
} from 'lodash';

import { formatPrice } from '~/utilities/products';
import {
    getBogoItems,
    getTotals,
    queryCartItem,
    createGtagEcommEvent
} from '~/utilities/cart';
import washes from '~/account/api/washes';

export const addItemToCart = async (
    id,
    location,
    currentItems,
    customerId,
    discounts,
    displayName = null,
    addOns = null,
    giftCardValue = null,
    vehicleID = null,
    garageID = null,
    quantity = 1,
    giftCardQuantity = 1
) => {
    let newItems = [...currentItems];
    const item = await queryCartItem(id, location, displayName, giftCardValue);
    if (item) {
        if (addOns) {
            item.addOns = addOns;
        }
        if (giftCardValue) {
            item.giftCardQuantity = giftCardQuantity;
        }
        if (vehicleID) {
            if (item.productType === 'subscription') {
                remove(newItems, {
                    productType: 'subscription',
                    vehicle: {
                        vehicleId: vehicleID.toString(),
                        garage: garageID
                    }
                });
            }
            item.vehicle = {
                vehicleId: vehicleID.toString(),
                garage: garageID
            };
        }

        const existingItemIndex = findIndex(newItems, {
            databaseId: item.databaseId
        });
        let newItem;
        if (
            item.productType !== 'subscription' &&
            existingItemIndex > -1 &&
            !item.addOns
        ) {
            newItem = {
                ...newItems[existingItemIndex],
                quantity: newItems[existingItemIndex].quantity + quantity
            };
            newItems[existingItemIndex] = newItem;
        } else {
            const addOns = map(item.addOns, (addOn) => ({
                ...addOn,
                priceDisplay: formatPrice(addOn.price)
            }));
            newItem = {
                ...item,
                addOns,
                quantity,
                giftCardValue,
                cartItemId: Date.now().toString(),
                priceDisplay: formatPrice(item.price)
            };
            newItems = [...newItems, newItem];
        }
        if (item.bogoThreshold) {
            newItems = getBogoItems(
                item.bogoThreshold,
                newItem.quantity,
                newItem.cartItemId,
                newItems
            );
        }
        createGtagEcommEvent('add_to_cart', [newItem]);
    }

    const {
        items,
        subTotal,
        tax,
        shipping,
        grandTotal,
        discount,
        giftCardBalance
    } = await getTotals(newItems, customerId, discounts);

    return {
        items,
        discounts,
        totals: {
            subTotal,
            tax,
            shipping,
            grandTotal,
            discount,
            giftCardBalance
        }
    };
};

export const removeItemFromCart = async (
    cartItemId,
    currentItems,
    customerId,
    discounts
) => {
    let removeItemIds = isArray(cartItemId) ? cartItemId : [cartItemId];

    for (const id of removeItemIds) {
        await washes.deleteInvoiceById(id, currentItems);
    }

    const removeBogoItems = filter(currentItems, ({ cartItemId }) =>
        find(removeItemIds, (removeCartItemId) =>
            startsWith(cartItemId, `${removeCartItemId}-bogo`)
        )
    );

    removeItemIds = [...removeItemIds, ...map(removeBogoItems, 'cartItemId')];

    const newItems = filter(
        currentItems,
        ({ cartItemId: itemID }) => !includes(removeItemIds, itemID)
    );

    const {
        items,
        subTotal,
        tax,
        shipping,
        grandTotal,
        discount,
        giftCardBalance
    } = await getTotals(newItems, customerId, discounts);

    const removeItems = filter(currentItems, ({ cartItemId }) => {
        return find(removeItemIds, (removeCartItemId) => {
            return cartItemId === removeCartItemId;
        });
    });
    createGtagEcommEvent('remove_from_cart', removeItems);

    return {
        items,
        discounts,
        totals: {
            subTotal,
            tax,
            shipping,
            grandTotal,
            discount,
            giftCardBalance
        }
    };
};

export const updateCartItemAddOns = async (
    cartItemId,
    addOns,
    currentItems,
    location,
    customerId,
    discounts
) => {
    let newItems = [...currentItems];
    const newItemIndex = findIndex(newItems, {
        cartItemId
    });
    const newItem = await queryCartItem(
        newItems[newItemIndex].id,
        location,
        newItems[newItemIndex].productType === 'digital'
            ? newItems[newItemIndex].title
            : null,
        newItems[newItemIndex].giftCardValue
    );
    if (newItem) {
        const newAddons = map(addOns, (addOn) => ({
            ...addOn,
            priceDisplay: formatPrice(addOn.price)
        }));
        const addedAddons = differenceBy(
            newAddons,
            newItems[newItemIndex].addOns,
            'id'
        );
        const removedAddons = differenceBy(
            newItems[newItemIndex].addOns,
            newAddons,
            'id'
        );
        if (addedAddons.length) {
            createGtagEcommEvent('add_to_cart', addedAddons);
        }
        if (removedAddons.length) {
            createGtagEcommEvent('remove_from_cart', removedAddons);
        }
        newItem.addOns = newAddons;
        newItems[newItemIndex] = {
            ...newItems[newItemIndex],
            ...newItem,
            priceDisplay: formatPrice(newItem.price)
        };
    } else {
        remove(newItems, ({ cartItemId: itemID }) =>
            itemID.includes(cartItemId)
        );
    }

    const {
        items,
        subTotal,
        tax,
        shipping,
        grandTotal,
        discount,
        giftCardBalance
    } = await getTotals(newItems, customerId, discounts);

    return {
        items,
        discounts,
        totals: {
            subTotal,
            tax,
            shipping,
            grandTotal,
            discount,
            giftCardBalance
        }
    };
};

export const updateCartPromoCode = async (
    promoCode,
    currentCodes,
    currentItems,
    customerId
) => {
    const newItems = [...currentItems];

    const promoCodes = [
        ...currentCodes,
        {
            code: promoCode
        }
    ];

    const {
        items,
        subTotal,
        tax,
        shipping,
        grandTotal,
        discount,
        giftCardBalance,
        promoCodeErrors,
        promoCodes: newPromoCodes,
        promoCodeErrorMsg
    } = await getTotals(newItems, customerId, promoCodes);

    if (promoCodeErrors.length) {
        return {
            success: false,
            items,
            totals: {
                subTotal,
                tax,
                shipping,
                grandTotal,
                discount,
                giftCardBalance
            },
            discounts: currentCodes,
            promoCodeErrorMsg
        };
    }

    return {
        success: true,
        items,
        totals: {
            subTotal,
            tax,
            shipping,
            grandTotal,
            discount,
            giftCardBalance
        },
        discounts: newPromoCodes
    };
};

export const removePromoCode = async (
    promoCode,
    currentItems,
    customerId,
    discounts
) => {
    const newDiscounts = filter(discounts, ({ code }) => code !== promoCode);
    const newItems = [...currentItems];
    for (const item of newItems) {
        if (item.promoCode && item.promoCode.code === promoCode) {
            delete item.promoCode;
        }
    }
    const {
        items,
        subTotal,
        tax,
        shipping,
        giftCardBalance,
        grandTotal,
        discount
    } = await getTotals(newItems, customerId, newDiscounts);
    return {
        items,
        totals: {
            subTotal,
            tax,
            shipping,
            giftCardBalance,
            grandTotal,
            discount
        },
        discounts: newDiscounts
    };
};

export const removeCartItemAddOn = async (
    cartItemId,
    addOnId,
    currentItems,
    customerId,
    discounts
) => {
    const newItems = [...currentItems];

    const itemIndex = findIndex(newItems, {
        cartItemId: cartItemId
    });

    const removedAddons = remove(newItems[itemIndex].addOns, { id: addOnId });

    const {
        items,
        subTotal,
        tax,
        shipping,
        giftCardBalance,
        grandTotal,
        discount,
        promoCodes
    } = await getTotals(newItems, customerId, discounts);

    createGtagEcommEvent('remove_from_cart', removedAddons);

    return {
        items,
        totals: {
            subTotal,
            tax,
            shipping,
            grandTotal,
            discount,
            giftCardBalance
        },
        discounts: promoCodes
    };
};

const updateVehicleInfoQuantity = (cartItemId, cartItems, quantity) => {
    const itemIndex = findIndex(cartItems, { cartItemId });
    const item = cartItems[itemIndex];
    const vehicleInformation = item?.vehicleInformation || [];

    if (isEmpty(vehicleInformation)) {
        return [...cartItems];
    }

    const vehicleInfoQuantity = vehicleInformation.length;

    if (vehicleInfoQuantity > quantity) {
        const info = vehicleInformation.slice(0, vehicleInfoQuantity - 1);
        const newCartItems = [...cartItems];
        newCartItems[itemIndex].vehicleInformation = info;
        return newCartItems;
    } else {
        const info = vehicleInformation[vehicleInfoQuantity - 1];
        const id = `${vehicleInfoQuantity}-${cartItemId}`;
        const newCartItems = [...cartItems];
        newCartItems[itemIndex].vehicleInformation.push({ ...info, id });
        return newCartItems;
    }
};

export const updateCartItemQuantity = async (
    cartItemId,
    quantity,
    currentItems,
    location,
    customerId,
    discounts
) => {
    let newItems = updateVehicleInfoQuantity(
        cartItemId,
        currentItems,
        quantity
    );

    const itemIndex = findIndex(newItems, { cartItemId });

    const newItem = await queryCartItem(
        newItems[itemIndex].id,
        location,
        newItems[itemIndex].productType === 'digital'
            ? newItems[itemIndex].title
            : null,
        newItems[itemIndex].giftCardValue
    );

    if (newItem) {
        const quantityDiff = quantity - newItem.quantity;
        newItem.quantity = quantity;
        newItem.cartItemId = cartItemId;

        if (newItem.bogoThreshold) {
            newItems = getBogoItems(
                newItem.bogoThreshold,
                newItem.quantity,
                newItem.cartItemId,
                newItems
            );
        }

        newItems[itemIndex] = {
            ...newItems[itemIndex],
            ...newItem,
            priceDisplay: formatPrice(newItem.price)
        };
        if (quantityDiff > 0) {
            createGtagEcommEvent(
                'add_to_cart',
                [
                    {
                        ...newItem,
                        quantity: quantityDiff
                    }
                ],
                quantityDiff
            );
        } else if (quantityDiff < 0) {
            createGtagEcommEvent(
                'remove_from_cart',
                [
                    {
                        ...newItem,
                        quantity: -quantityDiff
                    }
                ],
                quantityDiff
            );
        }
    } else {
        remove(newItems, ({ cartItemId: itemID }) =>
            itemID.includes(cartItemId)
        );
    }

    const {
        items,
        subTotal,
        tax,
        shipping,
        giftCardBalance,
        grandTotal,
        discount,
        promoCodes
    } = await getTotals(newItems, customerId, discounts);

    return {
        items,
        totals: {
            subTotal,
            tax,
            shipping,
            grandTotal,
            discount,
            giftCardBalance
        },
        discounts: promoCodes
    };
};

export const updateCartItemPrice = async (
    cartItemId,
    newPrice,
    currentItems,
    customerId,
    discounts
) => {
    const newItems = [...currentItems];
    const itemIndex = findIndex(newItems, { cartItemId });
    newItems[itemIndex].price = newPrice;
    newItems[itemIndex].priceDisplay = formatPrice(newPrice);
    const {
        items,
        subTotal,
        tax,
        shipping,
        giftCardBalance,
        grandTotal,
        discount,
        promoCodes
    } = await getTotals(newItems, customerId, discounts);
    return {
        items,
        totals: {
            subTotal,
            tax,
            shipping,
            grandTotal,
            discount,
            giftCardBalance
        },
        discounts: promoCodes
    };
};

export const updateCartItemLocation = async (
    cartItemId,
    location,
    currentItems,
    customerId,
    discounts
) => {
    let newItems = [...currentItems];
    const itemIndex = findIndex(newItems, { cartItemId });
    const newItem = await queryCartItem(
        newItems[itemIndex].id,
        location,
        newItems[itemIndex].productType === 'digital'
            ? newItems[itemIndex].title
            : null,
        newItems[itemIndex].giftCardValue
    );

    if (newItem) {
        newItems[itemIndex] = {
            ...newItems[itemIndex],
            ...newItem,
            priceDisplay: newItem.price
        };
    } else {
        remove(newItems, ({ cartItemId: itemID }) =>
            itemID.includes(cartItemId)
        );
    }

    const {
        items,
        subTotal,
        tax,
        shipping,
        giftCardBalance,
        grandTotal,
        discount,
        promoCodes
    } = await getTotals(newItems, customerId, discounts);
    return {
        items,
        totals: {
            subTotal,
            tax,
            shipping,
            giftCardBalance,
            grandTotal,
            discount
        },
        discounts: promoCodes
    };
};
