import { ampFetch } from './api';
import { has, parseInt } from 'lodash';
import { isLoggedIn } from '~/account/utilities/helpers';

/**
 * @typedef SubscriptionResponsePlan
 * @property {string} [name]
 * @property {number} product_id
 * @property {number} price_id
 * @property {number} quantity
 */

/**
 * @typedef SubscriptionResponse
 * @property {string} stripe_subscription_id
 * @property {string} [stripe_customer_id]
 * @property {string} [status]
 * @property {boolean} is_cancel_at_period_end datetime
 * @property {string} current_period_end datetime
 * @property {string} [downgrade_expiration_date] datetime
 * @property {string} [pause_request_date] datetime
 * @property {string} [pause_effective_date] datetime
 * @property {string} [location_id]
 * @property {string} created_date datetime
 * @property {string} modified_date datetime
 * @property {SubscriptionResponsePlan} plan
 */

const updateItemWithInvoice = (invoice, item, refreshKey) => {
    let discount = 0;

    if (invoice.discount) {
        discount = invoice.discount;
    } else if (invoice.total < invoice.subtotal) {
        discount = invoice.subtotal + invoice.tax - invoice.total;
    }

    return {
        ...item,
        invoice: {
            ...invoice,
            discount
        },
        discount,
        refreshKey,
        invoice_id: invoice.stripe_subscription_id,
        tax: invoice.tax,
        price: invoice.subtotal
    };
};

const subscriptions = {
    /**
     * @param vehicle_id
     * @param price_id
     * @param payment_method_id
     * @param promo_code_id
     * @param referral_code
     * @param closest_location_external_id
     * @param latitude
     * @param longitude
     * @param pos_plan_id
     * @returns {Promise<SubscriptionResponse>}
     */
    createSubscription: async (
        vehicle_id,
        price_id,
        payment_method_id,
        promo_code_id,
        referral_code,
        closest_location_external_id,
        latitude,
        longitude,
        pos_plan_id
    ) => {
        return await ampFetch({
            route: 'api-user/subscription/create-vehicle-subscription',
            options: {
                method: 'POST',
                body: JSON.stringify({
                    vehicle_id,
                    price_id,
                    payment_method_id,
                    promo_code_id,
                    referral_code,
                    closest_location_external_id,
                    latitude,
                    longitude,
                    pos_plan_id
                })
            }
        });
    },
    previewNewSubscriptionInvoice: async (
        item,
        closest_location_external_id
    ) => {
        if (!isLoggedIn()) {
            return { success: false, subscriptionItem: item };
        }

        const price_id = parseInt(item.price_id);
        const promo_code_id = item.promoCode?.id;
        const refreshKey = `${price_id}-${closest_location_external_id}-${promo_code_id}`;

        if (item.refreshKey === refreshKey && has(item, 'invoice')) {
            return { success: true, subscriptionItem: item };
        }

        const response = await ampFetch({
            route: 'api-user/subscription/preview-new-subscription-invoice',
            options: {
                method: 'POST',
                body: JSON.stringify({
                    add_price: {
                        price_id,
                        quantity: 1
                    },
                    promo_code_id,
                    closest_location_external_id,
                    billing_cycle_anchor: new Date().toISOString(),
                    proration_behavior: 'always_invoice'
                })
            }
        });

        if (response.success) {
            const subscriptionItem = updateItemWithInvoice(
                response.data,
                item,
                refreshKey
            );
            return { success: true, subscriptionItem };
        }

        return { success: false, subscriptionItem: item };
    },
    validateSubscriptionPromoCode: async (
        price_id,
        code_name,
        sub_total,
        phone_number
    ) => {
        return await ampFetch({
            route: 'api-user/subscription/validate-subscription-promotion-code',
            options: {
                method: 'POST',
                body: JSON.stringify({
                    price_id,
                    code_name,
                    sub_total,
                    phone_number
                })
            }
        });
    },
    /**
     *
     * @param vehicle_id
     * @param price_id
     * @param garage_id
     * @returns {Promise<SubscriptionResponse>}
     */
    updateSubscription: async (vehicle_id, price_id, garage_id) => {
        return await ampFetch({
            route: 'api-user/subscription/update-vehicle-subscription',
            options: {
                method: 'POST',
                body: JSON.stringify({
                    vehicle_id,
                    price_id,
                    'Amp-Account-Id': garage_id
                })
            }
        });
    },
    /**
     * @param subscription_id
     * @param garage_id
     * @param cancellation_reason_id
     * @param comment
     * @returns {Promise<SubscriptionResponse>}
     */
    cancelSubscription: async (
        subscription_id,
        garage_id,
        cancellation_reason_id,
        comment
    ) => {
        return await ampFetch({
            route: 'api-user/subscription/cancel-subscription',
            options: {
                method: 'POST',
                body: JSON.stringify({
                    subscription_id,
                    cancellation_reason_id,
                    comment,
                    'cancel_at_period_end': true,
                    'Amp-Account-Id': garage_id
                })
            }
        });
    },
    getCancellationReasons: async () => {
        return await ampFetch({
            route: 'api-user/get-dynamic-dropdown-values',
            options: {
                method: 'POST',
                body: JSON.stringify({
                    key: 'CANCELATION_REASONS'
                })
            }
        });
    }
};

export default subscriptions;
