import {
    filter,
    find,
    findIndex,
    has,
    includes,
    map,
    reduce,
    startsWith,
    sumBy,
    upperFirst,
    some
} from 'lodash';

import { graphqlFetch, stripTags } from '~/utilities/helpers';
import { formatPrice, getProductPrice } from '~/utilities/products';
import { validatePromoCode } from '~/utilities/promos';
import { isLoggedIn } from '~/account/utilities/helpers';

import giftCards from '~/account/api/giftCards';
import subscriptions from '~/account/api/subscriptions';
import physicalProductsApi from '~/account/api/physicalProducts';
import washes from '~/account/api/washes';

import AmexLogo from '~/images/credit-cards/amex.svg';
import DiscoverLogo from '~/images/credit-cards/discover.png';
import MastercardLogo from '~/images/credit-cards/mastercard.svg';
import VisaLogo from '~/images/credit-cards/visa.png';
import DefaultCard from '~/images/credit-cards/default.svg';

export const queryCartItem = async (
    id,
    location,
    displayName = null,
    giftCardValue = null
) => {
    if (displayName) {
        const query = `
        query {
            products(where: {displayName: "${displayName}"}) {
                nodes {
                    ampId
                    databaseId
                    title
                    displayName
                    locations
                    featuredImage {
                        node {
                            sourceUrl
                        }
                    }
                    productInformation {
                        price
                        showVehicleInformationForm
                        bogo {
                            enableBogo
                            threshold
                        }
                    }
                    stripeId
                    stripePrices(priceGroup: ${location.priceGroup}) {
                        priceGroup
                        label
                        stripeId
                        id
                        unitAmount
                    }
                    productTypes {
                        nodes {
                            slug
                        }
                    }
                    cleaningCategories {
                        nodes {
                            slug
                        }
                    }
                }                
            }
        }
    `;
        const {
            products: { nodes }
        } = await graphqlFetch(query);
        const product = find(nodes, ({ locations }) =>
            includes(locations, location.ampID)
        );
        if (product) {
            return {
                ampId: product?.ampId,
                id: product.databaseId,
                location: {
                    id: location.ampID,
                    address: location.address
                },
                title: product.displayName
                    ? product.displayName
                    : product.title,
                price:
                    giftCardValue ??
                    getProductPrice(
                        product,
                        product.productTypes.nodes[0].slug,
                        location.priceGroup,
                        false
                    ),
                bogoThreshold: product.productInformation.bogo.bogoEnabled
                    ? product.productInformation.bogo.threshold
                    : undefined,
                stripe_id: product.stripeId,
                price_id: product.stripePrices[0]?.id,
                stripe_price_id: product.stripePrices[0]?.stripeId,
                image: product.featuredImage?.node.sourceUrl,
                productType: product.productTypes.nodes[0].slug,
                showVehicleInformationForm:
                    !!product?.productInformation?.showVehicleInformationForm,
                cleaningCategories: product?.cleaningCategories?.nodes || []
            };
        }
    } else {
        const query = `
        query {
            product(id: "${id}", idType: DATABASE_ID) {
                ampId
                databaseId
                title
                displayName
                featuredImage {
                    node {
                        sourceUrl
                    }
                }
                productInformation {
                    price
                    showVehicleInformationForm
                    bogo {
                        enableBogo
                        threshold
                    }
                }
                stripeId
                stripePrices(priceGroup: ${location.priceGroup}) {
                    priceGroup
                    label
                    stripeId
                    id
                    unitAmount
                }
                productTypes {
                    nodes {
                        slug
                    }
                }
                cleaningCategories {
                    nodes {
                        slug
                    }
                }
            }
        }
    `;

        const { product } = await graphqlFetch(query);
        if (product) {
            return {
                ampId: product?.ampId,
                id: product.databaseId,
                location: {
                    id: location.ampID,
                    address: location.address
                },
                title: product.displayName
                    ? product.displayName
                    : product.title,
                price:
                    giftCardValue ??
                    getProductPrice(
                        product,
                        product.productTypes.nodes[0].slug,
                        location.priceGroup,
                        false
                    ),
                bogoThreshold: product.productInformation.bogo.enableBogo
                    ? product.productInformation.bogo.threshold
                    : undefined,
                stripe_id: product.stripeId,
                stripe_price_id: product.stripePrices[0]?.stripeId,
                price_id: product.stripePrices[0]?.id,
                image: product.featuredImage?.node.sourceUrl,
                productType: product.productTypes.nodes[0].slug,
                showVehicleInformationForm:
                    !!product?.productInformation?.showVehicleInformationForm,
                cleaningCategories: product?.cleaningCategories?.nodes || []
            };
        }
    }
    return null;
};

export const createGtagEcommEvent = (
    eventType,
    items,
    additionalValues = {}
) => {
    if (window.dataLayer) {
        const gtagItems = reduce(
            items,
            (acc, item) => {
                const gtagAddons = map(item.addOns, (addOn) => ({
                    item_id: addOn.id,
                    item_name: addOn.title,
                    quantity: item.quantity,
                    price: addOn.price / 100
                }));
                return [
                    ...acc,
                    {
                        item_id: item.id,
                        item_name: item.title,
                        quantity: item.quantity,
                        price: item.price / 100
                    },
                    ...gtagAddons
                ];
            },
            []
        );
        const eventData = {
            value: sumBy(gtagItems, ({ price }) => price * 100) / 100,
            currency: 'USD',
            items: gtagItems,
            ...additionalValues
        };
        window.dataLayer.push({
            event: eventType,
            ecommerce: eventData
        });
    }
};

export const getBogoItems = (threshold, quantity, cartItemId, cartItems) => {
    const bogoItemIndex = findIndex(cartItems, { cartItemId });
    const bogoItem = cartItems[bogoItemIndex];
    cartItems = cartItems.filter(
        ({ cartItemId: itemId }) => !startsWith(itemId, `${cartItemId}-bogo`)
    );
    if (quantity >= threshold) {
        const totalItemsToAdd = Math.floor(quantity / threshold);
        let bogoItems = [];
        for (let i = 0; i < totalItemsToAdd; i++) {
            bogoItems.push({
                ...bogoItem,
                title: `${bogoItem.title} - BOGO`,
                quantity: 1,
                bogoItem: true,
                price: 0,
                tax: 0,
                priceDisplay: 'FREE',
                cartItemId: `${cartItemId}-bogo-${i}`
            });
        }
        cartItems = cartItems.toSpliced(bogoItemIndex + 1, 0, ...bogoItems);
    }
    return cartItems;
};

export const canGuestCheckout = (items) => {
    return (
        items.length &&
        !some(items, ({ productType }) =>
            includes(['subscription', 'digital'], productType)
        )
    );
};

const createPhysicalProductsDraftInvoice = async (
    customerId,
    items,
    promoCode = null
) => {
    const physicalItemsInvoiceID = find(items, 'invoice_id')?.invoice_id;
    const response =
        await physicalProductsApi.createPhysicalProductsDraftInvoice(
            customerId,
            items,
            promoCode,
            physicalItemsInvoiceID
        );
    if (response.success) {
        let subtotal = response.data.subtotal;
        if (response.data.discount) {
            subtotal = subtotal + response.data.discount;
        }
        return {
            invoice_id: response.data.invoice_id,
            total: response.data.total,
            subTotal: subtotal,
            tax: response.data.tax,
            discount: response.data.discount,
            invoice: response.data,
            refreshKey: response.refreshKey
        };
    }
    return {
        error: response.errorCode
    };
};

const validateDiscounts = async (promoCodes, items, customerId) => {
    let physicalProductsInvoice = null;
    let promoCodeErrors = [];
    let promoCodesWithIDs = [];
    let promoCodeErrorMsg = '';
    let newItems = [...items];

    const subscriptionItems = filter(newItems, {
        productType: 'subscription'
    });

    const physicalProducts = filter(newItems, { productType: 'physical' });

    const digitalProducts = filter(newItems, { productType: 'digital' });

    for (const promoCode of promoCodes) {
        const { code } = promoCode;
        let didSucceed = false;
        let codeId = null;

        const { successItems, promoCodeId } = await validatePromoCode(
            code,
            customerId,
            subscriptionItems
        );

        if (successItems.length) {
            for (const item of successItems) {
                const { cartItemId, promoCodeId } = item;
                if (cartItemId) {
                    const newItemIndex = findIndex(newItems, {
                        cartItemId
                    });
                    newItems[newItemIndex].promoCode = {
                        id: promoCodeId,
                        code
                    };
                }
            }
            //Prevent double application of promo codes
            for (const item of physicalProducts) {
                const newItemIndex = findIndex(newItems, {
                    cartItemId: item.cartItemId
                });
                delete newItems[newItemIndex].promoCode;
            }
            didSucceed = true;
            codeId = promoCodeId;
        }

        if (physicalProducts.length && !didSucceed) {
            const response = await createPhysicalProductsDraftInvoice(
                customerId,
                physicalProducts,
                code
            );

            if (!response.error) {
                physicalProductsInvoice = response;
                for (const item of physicalProducts) {
                    const newItemIndex = findIndex(newItems, {
                        cartItemId: item.cartItemId
                    });
                    newItems[newItemIndex].promoCode = {
                        id: response.promo_code_id,
                        code
                    };
                }
                didSucceed = true;
                codeId = response.promo_code_id;
            }
        }

        if (digitalProducts.length && !didSucceed) {
            for (const item of digitalProducts) {
                if (!has(item, 'vehicle')) {
                    promoCodeErrorMsg = `You must select a vehicle first before you can add a promotional code for ${item.title}`;
                    continue;
                }

                const { digitalItem } = await washes.addInvoice(
                    item.location.id,
                    item,
                    code
                );

                const { success, value } = await washes.addPromoCode(
                    code,
                    item.location.id,
                    digitalItem
                );

                const newItemIndex = findIndex(newItems, {
                    cartItemId: digitalItem.cartItemId
                });

                if (success && newItemIndex > -1) {
                    newItems[newItemIndex] = value;
                    didSucceed = true;
                } else if (newItemIndex > -1) {
                    newItems[newItemIndex] = digitalItem;
                }
            }
        }

        if (didSucceed) {
            promoCodesWithIDs.push({
                code,
                id: codeId
            });
        } else {
            promoCodeErrors.push(code);
        }
    }

    if (physicalProducts.length && !physicalProductsInvoice) {
        const response = await createPhysicalProductsDraftInvoice(
            customerId,
            physicalProducts
        );

        if (!response.error) {
            physicalProductsInvoice = response;
        }
    }

    const addInvoiceInfoForPhysicalProduct = () => {
        return map(newItems, (item) => {
            if (item.productType === 'physical') {
                const physicalTotal =
                    (item.price + sumBy(item.addOns, 'price')) * item.quantity;

                const fallback = {
                    subTotal: physicalTotal,
                    total: physicalTotal,
                    discount: 0,
                    tax: 0
                };

                const physicalInvoice = physicalProductsInvoice ?? fallback;

                return {
                    ...item,
                    invoice_id: physicalInvoice.invoice_id
                };
            }
            return item;
        });
    };

    return {
        promoCodeErrorMsg,
        promoCodeErrors,
        physicalProductsInvoice,
        promoCodes: promoCodesWithIDs,
        items: addInvoiceInfoForPhysicalProduct()
    };
};

export const getTotals = async (items, customerId, discounts = []) => {
    const {
        items: newItems,
        physicalProductsInvoice,
        promoCodeErrors,
        promoCodes,
        promoCodeErrorMsg
    } = await validateDiscounts(discounts, items, customerId);

    const totals = await reduce(
        [...newItems],
        async (totals, item, index) => {
            let awaitedTotals = await totals;

            switch (item.productType) {
                case 'gift-card':
                    const { giftItem } = await giftCards.getInvoice(
                        item,
                        item.location.id
                    );

                    const giftDiscount = giftItem.discount ?? 0;
                    const giftTax = giftItem.tax ?? 0;
                    const giftSubTotal = giftItem.subtotal ?? item.price;
                    const giftTotal = giftItem.total ?? item.price;

                    awaitedTotals.discount += giftDiscount;
                    awaitedTotals.tax += giftTax * item.quantity;
                    awaitedTotals.subTotal += giftSubTotal * item.quantity;
                    awaitedTotals.grandTotal += giftTotal * item.quantity;

                    awaitedTotals.items[index] = giftItem;

                    return awaitedTotals;
                case 'subscription':
                    const { subscriptionItem } =
                        await subscriptions.previewNewSubscriptionInvoice(
                            item,
                            item.location.id
                        );

                    const subscriptionInvoice = subscriptionItem.invoice;
                    const subscriptionTax = subscriptionInvoice?.tax ?? 0;
                    const subscriptionDiscount =
                        subscriptionInvoice?.discount ?? 0;
                    const subscriptionSubTotal =
                        subscriptionInvoice?.subtotal ?? item.price;
                    const subscriptionGrandTotal =
                        subscriptionInvoice?.total ??
                        item.price + subscriptionTax;

                    awaitedTotals.tax += subscriptionTax;
                    awaitedTotals.discount += subscriptionDiscount;
                    awaitedTotals.subTotal += subscriptionSubTotal;
                    awaitedTotals.grandTotal += subscriptionGrandTotal;

                    awaitedTotals.items[index] = subscriptionItem;

                    return awaitedTotals;
                case 'physical':
                    if (!physicalProductsInvoice) {
                        awaitedTotals.discount += item.discount;
                        awaitedTotals.tax += item.tax;
                        awaitedTotals.subTotal += item.subTotal;
                        awaitedTotals.grandTotal += item.total;
                    }

                    awaitedTotals.items[index] = item;

                    return awaitedTotals;
                case 'digital':
                    const { digitalItem, digitalTotal } =
                        await washes.addInvoice(item.location.id, item);

                    const digitalInvoice = digitalItem.invoice;
                    const digitalTax = digitalInvoice?.tax ?? 0;
                    const digitalDiscount = digitalInvoice?.discount ?? 0;
                    const digitalSubTotal =
                        digitalInvoice?.subtotal ?? digitalTotal;
                    const digitalGrandTotal =
                        digitalInvoice?.total ?? digitalTotal;

                    awaitedTotals.tax += digitalTax;
                    awaitedTotals.discount += digitalDiscount;
                    awaitedTotals.subTotal += digitalSubTotal;
                    awaitedTotals.grandTotal += digitalGrandTotal;

                    awaitedTotals.items[index] = digitalItem;

                    return awaitedTotals;
                default:
                    console.error(`Unknown product type: ${item.productType}`);
                    awaitedTotals.subTotal += item.price * item.quantity;
                    awaitedTotals.grandTotal += item.price * item.quantity;
                    awaitedTotals.items[index] = item;

                    return awaitedTotals;
            }
        },
        Promise.resolve({
            subTotal: 0,
            tax: 0,
            shipping: 0,
            grandTotal: 0,
            discount: 0,
            items: newItems
        })
    );

    if (physicalProductsInvoice) {
        totals.discount += physicalProductsInvoice.discount ?? 0;
        totals.tax += physicalProductsInvoice.tax ?? 0;
        totals.subTotal += physicalProductsInvoice.subTotal;
        totals.grandTotal += physicalProductsInvoice.total;
    }

    if (isLoggedIn()) {
        const {
            data: { balance }
        } = await giftCards.getGiftCardsBalance();
        if (balance) {
            const giftBalance = balance * -1;
            totals.giftCardBalance =
                totals.grandTotal > giftBalance
                    ? giftBalance
                    : totals.grandTotal;
            totals.grandTotal -= totals.giftCardBalance;
        }
    }

    return { ...totals, promoCodeErrors, promoCodes, promoCodeErrorMsg };
};

export const getCreditCardLogo = (brand) => {
    switch (brand) {
        case 'visa':
            return VisaLogo;
        case 'mastercard':
            return MastercardLogo;
        case 'amex':
            return AmexLogo;
        case 'discover':
            return DiscoverLogo;
        default:
            return DefaultCard;
    }
};

export const getCouponErrorMessage = (error) => {
    switch (error) {
        case 'COUPON_NOT_FOUND':
            return 'Coupon not found';
        case 'PURCHASE_TYPE':
            return 'No products in your cart match the criteria for the promo code.';
        default:
            return error;
    }
};

export const getCartErrorMessage = (error, items, refunds) => {
    const { cartItemId, newPrice } = error;
    const cartItem = find(items, { cartItemId });
    const refundObj = find(refunds, { cartItemId });
    const title = stripTags(cartItem?.title || 'the item');

    const addons = cartItem?.addOns ?? [];
    const addonPrice = sumBy(addons, 'price');
    const cartPrice = cartItem?.price ?? 0;
    const tax = cartItem?.tax ?? 0;
    const price = (cartPrice + addonPrice + tax) / 100;
    const priceDisplay = `$${price.toFixed(2)}`;

    let actionMsg = 'Please try again later.';

    if (refundObj) {
        const { refund } = refundObj;
        if (refund?.status === 'succeeded') {
            actionMsg = `We sincerely apologize for the inconvenience caused. Your account was charged ${priceDisplay} and a refund was immediately issued.`;
        } else {
            actionMsg = `We sincerely apologize for the inconvenience caused. Your account was charged ${priceDisplay} but could not be refunded.`;
        }
    }

    switch (error.error) {
        case 'INVOICE_ITEM_NOT_FOUND':
            return `${upperFirst(title)} is not currently available. Please try again later.`;
        case 'INVOICE_PRICE_MISMATCH':
            return `The price of ${title} has changed to ${formatPrice(newPrice)} since adding it to your cart. Please confirm the new price before proceeding.`;
        default:
            return `There was an error purchasing the ${title}. ${actionMsg}`;
    }
};
