import React, { useContext, createContext, useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import { reduce, some } from 'lodash';

import { parseQueryString } from '~/utilities/helpers';

const LocationContext = createContext();

const utms = [
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_term',
    'utm_content'
];

export const LocationProvider = ({ children, pageProps }) => {
    const [location, setLocation] = useState(pageProps?.location || {});

    useEffect(() => {
        if (pageProps?.location) {
            const cookies = new Cookies();
            const { search } = pageProps.location;
            const query = parseQueryString(search);
            if (query && some(utms, (utm) => query[utm])) {
                // Setting a cookie to persist the user's UTMs across pages
                cookies.set(
                    'utms',
                    reduce(
                        utms,
                        (carry, utm) => {
                            carry[utm] = query[utm] || '';
                            return carry;
                        },
                        {}
                    ),
                    {
                        path: '/',
                        expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 7) // 1 week
                    }
                );
            }
            setLocation({
                ...pageProps.location,
                utms: cookies.get('utms') || {}
            });
        }
    }, [pageProps?.location]);

    return (
        <LocationContext.Provider value={location}>
            {children}
        </LocationContext.Provider>
    );
};

export const useLocation = () => {
    const context = useContext(LocationContext);

    if (context === undefined) {
        throw new Error(
            'useLocationContext must be used within a LocationProvider'
        );
    }

    return context;
};
