import { isBrowser } from '~/utilities/helpers';

/**
 * @param {string} token
 * @param {string} expires_at
 *
 * @returns void
 */
export const saveToken = ({ token, expires_at }) => {
    if (isBrowser()) {
        localStorage.setItem('ampToken', token);
        localStorage.setItem('ampExpires', expires_at);
    }
};

/**
 * @returns {boolean}
 */
export const isTokenExpired = () => {
    const expires = getTokenExpiration();

    if (expires) {
        const expiredDate = new Date(expires);
        const nowDate = new Date();

        return expiredDate < nowDate;
    }

    return true;
};

/**
 * @returns {string|null}
 */
export const getToken = () => {
    if (isBrowser()) {
        if (!isTokenExpired()) {
            return localStorage.getItem('ampToken');
        } else {
            localStorage.removeItem('ampToken');
            localStorage.removeItem('ampExpires');
        }
    }
    return null;
};

/**
 * @returns {string|null}
 */
export const getTokenExpiration = () => {
    if (isBrowser()) {
        return localStorage.getItem('ampExpires');
    }
    return null;
};

/**
 * @returns {void}
 */
export const resetToken = () => {
    if (isBrowser()) {
        localStorage.removeItem('ampToken');
        localStorage.removeItem('ampExpires');
    }
};

/**
 * @returns {boolean|""|null}
 */
export const isLoggedIn = () => {
    if (isBrowser()) {
        return getToken() && !isTokenExpired();
    }
    return false;
};

/**
 * @param vehicleId
 *
 * @returns {void}
 */
export const setDefaultVehicleId = (vehicleId) => {
    if (isBrowser() && vehicleId) {
        localStorage.setItem('defaultVehicleId', vehicleId);
    }
};

/**
 * @returns {string|null}
 */
export const getDefaultVehicleId = () => {
    if (isBrowser()) {
        return localStorage.getItem('defaultVehicleId');
    }
    return null;
};

export const unknownErrorMessage = 'An unknown error has occurred.';

export const errorMessageOverrides = {
    USERNAME_EXISTS:
        'There is already an account associated with that email address.',
    INVALID_PARAMETER: 'Please ensure all fields are valid.',
    LIMIT_EXCEEDED: 'Number of forgot password attempts has been exceeded.',
    NOT_AUTHORIZED: 'Not authorized to perform this function.',
    INVALID_PASSWORD: 'Please enter a valid password.',
    CODE_MISMATCH: 'The provided confirmation code does not match.',
    EXPIRED_CODE:
        'The provided confirmation code has expired. Please request a new one.',
    TOO_MANY_FAILED_ATTEMPTS:
        'Too many failed attempts, please try again later.',
    FAILURE: 'An error has occurred processing your submission.',
    CANNOT_FIND_VEHICLE: 'Vehicle not found.',
    VEHICLE_SUBSCRIPTION_NOT_FOUND:
        'This vehicle does not have a current subscription. You will need to create a new subscription.',
    USER_ALREADY_ON_ACCOUNT:
        'This user is already associated with this garage.',
    USER_NOT_ON_ACCOUNT: 'This user is not associated with this garage.',
    ARCHIVED: 'This user account has been archived.',
    COMPROMISED: 'This user account has been compromised.',
    AMP_Maintenance:
        "We're currently undergoing maintenance. Please try again later.",
    UNKNOWN: unknownErrorMessage
};

export const getErrorMessage = (status) => {
    if (status === 'SUCCESS') {
        return null;
    } else if (errorMessageOverrides.hasOwnProperty(status)) {
        return errorMessageOverrides[status];
    } else {
        return unknownErrorMessage;
    }
};

export const emailRegex =
    /^[_a-z0-9-]+(\.[_a-z0-9-]+)*(\+[a-z0-9-]+)?@[a-z0-9-]+(\.[a-z0-9-]+)*$/i;
