import { ThemeProvider } from '@emotion/react';
import React from 'react';

import { GravityFormsProvider } from '~/context/GravityFormsContext';
import { StickyProvider } from '~/context/StickyContext';
import { LocationProvider } from '~/context/LocationContext';
import { LocationPickerProvider } from '~/context/LocationPickerContext';
import { UserContextProvider } from '~/context/UserContext';
import { CartContextProvider } from '~/context/CartContext';

import THEME from '~/theme';

const GlobalContext = ({ element, props }) => (
    <LocationProvider pageProps={props}>
        <LocationPickerProvider>
            <UserContextProvider>
                <CartContextProvider>
                    <GravityFormsProvider>
                        <StickyProvider>
                            <ThemeProvider theme={THEME}>
                                {element}
                            </ThemeProvider>
                        </StickyProvider>
                    </GravityFormsProvider>
                </CartContextProvider>
            </UserContextProvider>
        </LocationPickerProvider>
    </LocationProvider>
);

export const wrapRootElement = ({ element }) => {
    return <GlobalContext element={element} />;
};

export const wrapPageElement = ({ element, props }) => {
    return <GlobalContext element={element} props={props} />;
};
